import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { NavMenu } from './nav/NavMenu';
import { Row, Col, Container } from 'react-bootstrap';
import { Footer } from './Footer';

export class Layout extends Component {
    displayName = Layout.name;

    static contextTypes = {
        getMenuState: PropTypes.func,
        setMenuState: PropTypes.func
    };

    getMenuState() {
        const { getMenuState } = this.context;
        return getMenuState();
    }

    toggleMenuHandler() {
        const { setMenuState } = this.context;
        setMenuState();
    }

    render() {
        const isMenuOpen = this.getMenuState();

        return (
            <Container fluid>
                <Row className="no-padding">
                    <Col className="no-padding">
                        <NavMenu isMenuOpen={isMenuOpen} toggleMenuHandler={() => this.toggleMenuHandler()} />
                        <div className={`layout-content ${isMenuOpen ? "menu-open" : ""}`}>
                            {this.props.children}
                            <Footer marginClass={isMenuOpen ? "margin-left-250" : "margin-left-50"} />
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
