import { POIAPI } from '../../api/POIAPI';

export class POICommand {
    getNearPOI(latitude, longitude, radius, successCallback, errorCallback) {
        const apiResult = POIAPI.getNearPOI(latitude, longitude, radius);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }
}
