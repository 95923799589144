import React, { Component } from 'react';
import { strings } from '../resources/strings';

export class NotFound extends Component {
    render() {
        return (
            <div className="not-found-panel">
                <img className="not-found-image" src="icons/404.png" alt="" height="500px" />
                <span className="page-not-found-text">{strings.pageNotFound}</span>
            </div>
        );
    }
}
