import React, { Component } from 'react';

export class RecenterButton extends Component {
    render() {
        const { className, recenter } = this.props;

        return (
            <div className={className}>
                <button className="recenter-button" onClick={() => recenter()}>
                    <div className="icon-recenter" />
                </button>
            </div>
        );
    }
}