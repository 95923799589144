import React, { Component } from 'react';

export class SwitchDirectionButton extends Component {
    render() {
        const { className, switchDirections } = this.props;

        return (
            <div className={className}>
                <button type="button" className="switch-direction-button" onClick={() => switchDirections()}>
                    <div className="rotate-90 icon-network" />
                </button>
            </div>
        );
    }
}