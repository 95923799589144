import { API, HTTPMethod, ContentType } from './APICentral';
import { APIEncoder } from './APIEncoder';

const scheduleApiRoutes = {
    defaultRoute: "schedule/"
};

export class ScheduleAPI {
    static getScheduleByLineAndDate(lineId, stopId, direction, date) {
        const url = `${scheduleApiRoutes.defaultRoute}${lineId}/${stopId}/${direction}/${date}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }

    static getScheduleByLineGroupByDayType(lineId) {
        const url = `${scheduleApiRoutes.defaultRoute}${lineId}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }
}