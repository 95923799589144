import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { AllRoutesView } from './AllRoutesView';
import { RouteDetailedView } from './RouteDetailedView';
import { Disclaimer } from '../Disclaimer';

export class PlanRoute extends Component {
    static contextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func,
        getMenuState: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.stateKey = "planRoutes";

        this.state = {
            render: {
                component: null,
                key: "allRoutesView",
                args: {}
            },
            available: {
                allRoutesView: {
                    type: AllRoutesView,
                    callbacks: {
                        onSelectRoute: (args) => this.onChangeView("routeDetailedView", args)
                    }
                },
                routeDetailedView: {
                    type: RouteDetailedView,
                    callbacks: {
                        onReturn: (args) => this.onChangeView("allRoutesView", args),
                    }
                }
            }
        }
    }

    componentDidMount() {
        const { getState } = this.context;
        const state = getState(this.stateKey);

        if (undefined === state) {
            const { render, available } = this.state;
            render.component = available.allRoutesView;
            this.setState({
                render: render
            });
        } else {
            const { render, available } = this.state;

            render.component = available[state.key];
            render.key = state.key;
            render.args = state.args;

            this.setState({
                render: render
            });
        }
    }

    componentWillUnmount() {
        const { setState } = this.context;
        const { render } = this.state;

        setState(this.stateKey, render);
    }

    onChangeView(view, args) {
        const { render, available } = this.state;
        render.component = available[view];
        render.key = view;
        render.args = args;

        this.setState({
            render: render
        });
    }

    render() {
        const { render } = this.state;
        const isMenuOpen = this.context.getMenuState();

        if (null === render.component || undefined === render.component) {
            return null;
        }

        const view = React.createElement(
            render.component.type,
            Object.assign(render.component.callbacks, render.args)
        );

        return (
            <div className="plan-route-view">
                {view}
                <Disclaimer marginClass={isMenuOpen ? "margin-left-250" : "margin-left-50"} />
            </div>
        );
    }
}

