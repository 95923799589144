import { API, HTTPMethod, ContentType } from './APICentral';
import { APIEncoder } from './APIEncoder';

const tripApiRoutes = {
    defaultRoute: "trip/",
    vehiclePositionRoute: "trip/vehicle/"
};

export class TripAPI {
    static getTrip(tripId) {
        const url = `${tripApiRoutes.defaultRoute}${tripId}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }

    static getEstimatedTrip(provider, lineId, direction, stopId) {
        const url = `${tripApiRoutes.defaultRoute}${provider}/${lineId}/${direction}/${stopId}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }

    static getTripsByLineAndDayType(lineId, direction, schedules) {
        const url = `${tripApiRoutes.defaultRoute}${lineId}/${direction}/${schedules}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }

    static getVehiclePosition(provider, vehicleNr, vehicleTrip) {
        const url = `${tripApiRoutes.vehiclePositionRoute}${provider}/${vehicleNr}/${vehicleTrip}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }
}