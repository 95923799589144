import React, { Component, createRef } from 'react';
import { PropTypes } from 'prop-types';
import { DetailedRouteMap } from './DetailedRouteMap';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { strings } from '../../resources/strings';

export class RouteDetailedView extends Component {
    static contextTypes = {
        getLogo: PropTypes.func,
        getMenuState: PropTypes.func,
        setMenuState: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.detailedRouteMapRef = createRef();

        this.state = {
            displayTripInfo: true,
            displayMap: true
        }
    }

    componentDidMount() {
        /*
         * Set function to handle when window is resized and immediately call it so we check the current screen size, otherwise it is only
         * triggered on resize.
        */
        window.onresize = () => this.resize();
        this.resize();
    }

    componentWillUnmount() {
        window.onresize = () => { };
    }

    //-------

    resize() {
        if (window.innerWidth > 767) {
            this.setState({
                displayTripInfo: true,
                displayMap: true
            });
        } else {
            if (this.context.getMenuState()) {
                this.context.setMenuState();
            }
            document.getElementById("route-detailed-view-show-trip-button").click();
        }
    }

    determineTripIcon(trip) {
        if (trip.subTrips.length === 1 && trip.subTrips[0].isWalking) {
            return "icons/walking.png";
        } else {
            return "icons/bus.png";
        }
    }

    determineLineCode(trip) {
        const subtrip = trip.subTrips.find(st => undefined !== st && null !== st.lineCode && undefined !== st.lineCode);
        if (undefined !== subtrip) {
            return subtrip.lineCode;
        }

        return "";
    }

    displayTripInfo() {
        this.setState({
            displayTripInfo: true,
            displayMap: false
        });
    }

    displayMap() {
        this.setState({
            displayTripInfo: false,
            displayMap: true
        });
    }

    handleSubtripClick(subtrip) {
        this.detailedRouteMapRef.current.panToSpecificSubtrip(subtrip);
    }

    //RENDER
    renderDisplay() {
        const { displayTripInfo, displayMap } = this.state;
        const { trip } = this.props;

        if (displayTripInfo && displayMap) {
            return (
                <Row>
                    <Col sm={5} className="route-detailed-view-container-passings-col">
                        {this.renderInfo(trip)}
                    </Col>
                    <Col sm={7} className="route-detailed-view-container-map-col">
                        <DetailedRouteMap ref={this.detailedRouteMapRef} trip={trip} />
                    </Col>
                </Row>
            );
        } else {
            if (displayTripInfo) {
                return (
                    <Row>
                        <Col sm={12} className="route-detailed-view-container-passings-col">
                            {this.renderInfo(trip)}
                        </Col>
                    </Row>
                );
            } else {
                return (
                    <Row>
                        <Col sm={12} className="route-detailed-view-container-map-col">
                            {this.renderHeader(trip)}
                            <DetailedRouteMap ref={this.detailedRouteMapRef} trip={trip} />
                        </Col>
                    </Row>
                );
            }
        }
    }

    renderInfo(trip) {
        if (undefined === trip) {
            return null;
        }

        return (
            <div className="route-detailed-view-container-passings-col-trip-info">
                {this.renderHeader(trip)}
                {this.renderTripInfo(trip)}
            </div>
        );
    }

    renderHeader(trip) {
        const { onReturn } = this.props;
        const { displayMap, displayTripInfo } = this.state;

        return (
            <div>
                <Button className="plan-route-detailed-view-back-button margin-bottom-10" variant="light" onClick={() => onReturn()}>
                    <div className={"icon-redo"} />
                </Button>

                <div className="route-detailed-view-small-screen-tabs">
                    <Button id="route-detailed-view-show-trip-button" className={`route-detailed-view-small-screen-tabs-link-button ${displayTripInfo ? "orange-text" : ""}`} variant="link" onClick={() => this.displayTripInfo()}>
                        {strings.showTrip}
                    </Button>
                    <span className="orange-text">|</span>
                    <Button id="route-detailed-view-show-map-button" className={`route-detailed-view-small-screen-tabs-link-button ${displayMap ? "orange-text" : ""}`} variant="link" onClick={() => this.displayMap()}>
                        {strings.showMap}
                    </Button>
                </div>

                {/*<Row className=" align-items-center width-95">
                    <Col xs={2} sm={2} className="text-align-end">
                        <img src={this.determineTripIcon(trip)} alt="" height="30px" width="30px" />
                    </Col>
                    <Col xs={10} sm={10} className="plan-route-trips-list-trip-header-route-info">
                        <div className="d-flex-inline text-truncate">
                            <b>{this.determineLineCode(trip)}</b> {trip.endPlace.name}
                        </div>
                    </Col>
               <Col xs={4} sm={3} className="plan-route-trips-list-trip-header-duration-info">
                        <span>{strings.duration}: {trip.totalTripDuration}</span>
                    </Col>
                </Row>*/}
            </div>
        );
    }

    renderTripInfo(trip) {
        return (
            <div className="plan-route-detailed-view-trip-info">
                {
                    trip.subTrips.map((subtrip, index) => {
                        if (index === 0) {
                            if (trip.subTrips.length - 1 === index) {
                                return this.renderSingleSubtrip(trip, subtrip);
                            } else {
                                return this.renderFirstSubtrip(trip, subtrip);
                            }
                        } else if (trip.subTrips.length - 1 === index) {
                            return this.renderLastSubtrip(trip, subtrip);
                        } else {
                            return this.renderIntermediateSubtrip(trip, subtrip, index);
                        }
                    })
                }

                <div className="plan-route-detailed-view-trip-duration-info">
                    <span>{strings.duration}: {trip.totalTripDuration}</span>
                </div>

            </div>
        );
    }

    renderSingleSubtrip(trip, subtrip) {
        const { getLogo } = this.context;
        return (
            <div key={`single-trip-${trip.startPlace.name}-${trip.endPlace.name}`} onClick={() => this.handleSubtripClick(subtrip)}>
                <span className="plan-route-detailed-view-subtrip-hour-span">{subtrip.departureTimeToShow}</span>
                <img className="margin-left-negative3" src="icons/prOrigin.png" alt="" width="25px" height="25px" />
                &nbsp;
                <b>{trip.startPlace.name}</b>

                {
                    subtrip.isWalking ?
                        <div className="d-flex align-items-center padding-top-10 padding-bottom-10 cursor-pointer">
                            <div className="width-50px text-align-center">
                                <img src="icons/walking.png" alt="" height="20px" />
                            </div>
                            <div className="vertical-dotted-line" />
                            <span className="padding-left-10">{strings.walkAround} {subtrip.walkingTime}, {subtrip.distance}m</span>
                        </div>
                        :
                        <div className="d-flex align-items-center padding-top-10 padding-bottom-10 cursor-pointer">
                            <div className="width-50px text-align-center">
                                <img src="icons/bus.png" alt="" width="20px" height="20px" />
                            </div>
                            <div className="vertical-filled-line" />
                            &nbsp;
                            <div className="padding-left-10">
                                <span>{subtrip.lineCode} {subtrip.passings[0].destination}</span>
                            </div>
                        </div>
                }

                <span className="plan-route-detailed-view-subtrip-hour-span">{subtrip.arrivalTimeToShow}</span>
                <img className="margin-left-negative3" src="icons/prDestination.png" alt="" width="25px" height="25px" />
                &nbsp;
                <b>{trip.endPlace.name}</b>
            </div>
        );


        /*return (
            <div key={`single-trip-${trip.startPlace.name}-${trip.endPlace.name}`}>
                <img src="icons/prOrigin.png" alt="" width="20px" height="20px" />
                &nbsp;
                <b>{subtrip.departureTimeToShow} - {trip.startPlace.name}</b>

                {
                    subtrip.isWalking ?
                        <div className="padding-top-10 padding-bottom-10">
                            <img src="icons/walking.png" alt="" width="20px" height="20px" />
                            <span>{strings.walkAround} {subtrip.walkingTime}, {subtrip.distance}m</span>
                        </div>
                        :
                        //TO DO: Use transport types here
                        <div className="padding-top-10 padding-bottom-10" >
                            <img src="icons/bus.png" alt="" width="20px" height="20px" />
                            &nbsp;
                            <img src={getLogo(subtrip.provider)} alt={subtrip.provider} width="20px" height="20px" />
                            &nbsp;
                            <span>{subtrip.lineCode} {subtrip.passings[0].destination}</span>
                        </div>
                }

                <img src="icons/prDestination.png" alt="" width="20px" height="20px" />
                &nbsp;
                <b>{subtrip.arrivalTimeToShow} - {trip.endPlace.name}</b>
            </div>
        );*/
    }

    renderFirstSubtrip(trip, subtrip) {
        const { getLogo } = this.context;

        return (
            <div key={`first-subtrip-${trip.startPlace.name}-${trip.endPlace.name}-${subtrip.order}`} onClick={() => this.handleSubtripClick(subtrip)}>
                <span className="plan-route-detailed-view-subtrip-hour-span">{subtrip.departureTimeToShow}</span>
                <img className="margin-left-negative3" src="icons/prOrigin.png" alt="" width="25px" height="25px" />
                &nbsp;
                <b>{trip.startPlace.name}</b>

                {
                    subtrip.isWalking ?
                        <div className="d-flex align-items-center padding-top-10 padding-bottom-10 cursor-pointer">
                            <div className="width-50px text-align-center">
                                <img src="icons/walking.png" alt="" height="20px" />
                            </div>
                            <div className="vertical-dotted-line" />
                            <span className="padding-left-10">{strings.walkAround} {subtrip.walkingTime}, {subtrip.distance}m</span>
                        </div>
                        :
                        <div className="d-flex align-items-center padding-top-10 padding-bottom-10 cursor-pointer">
                            <div className="width-50px text-align-center">
                                <img src="icons/bus.png" alt="" height="20px" />
                            </div>
                            <div className="vertical-filled-line" />
                            &nbsp;
                            <div className="padding-left-10">
                                <img src={getLogo(subtrip.provider)} alt={subtrip.provider} height="20px" />
                                &nbsp;
                                <span>{subtrip.lineCode} {subtrip.passings[0].destination}</span>
                            </div>
                        </div>
                }

                <span className="plan-route-detailed-view-subtrip-hour-span">{subtrip.arrivalTimeToShow}</span>
                <img className="margin-left-negative3" src="icons/prDestination.png" alt="" width="25px" height="25px" />
                &nbsp;
                <span>{subtrip.passings[subtrip.passings.length - 1].name}</span>
            </div>
        );


        /*return (
            <div key={`first-subtrip-${trip.startPlace.name}-${trip.endPlace.name}-${subtrip.order}`}>
                <img src="icons/prOrigin.png" alt="" width="20px" height="20px" />
                &nbsp;
                <b>{subtrip.departureTimeToShow} - {trip.startPlace.name}</b>

                {
                    subtrip.isWalking ?
                        <div className="padding-top-10 padding-bottom-10">
                            <img src="icons/walking.png" alt="" width="20px" height="20px" />
                            <span>{strings.walkAround} {subtrip.walkingTime}, {subtrip.distance}m</span>
                        </div>
                        :
                        //TO DO: Use transport types here
                        <div className="padding-top-10 padding-bottom-10" >
                            <img src="icons/bus.png" alt="" width="20px" height="20px" />
                            &nbsp;
                            <img src={getLogo(subtrip.provider)} alt={subtrip.provider} width="20px" height="20px" />
                            &nbsp;
                            <span>{subtrip.lineCode} {subtrip.passings[0].destination}</span>
                        </div>
                }

                <img className="margin-left-5" src="icons/prIntermediate.png" alt="" width="10px" height="10px" />
                &nbsp;
                <span>{subtrip.passings[subtrip.passings.length - 1].name}</span>
                <br />
                {
                    trip.subTrips[1].isWalking ?
                        <span className="padding-left-3em">{strings.arrivalEstimatedAt} {subtrip.arrivalTimeToShow}</span>
                        :
                        <span className="padding-left-3em">{strings.estimatedWaitingTime} {subtrip.arrivalTimeToShow} {strings.and} {trip.subTrips[1].departureTimeToShow}</span>
                }

            </div>
        );*/
    }

    renderIntermediateSubtrip(trip, subtrip, index) {
        const { getLogo } = this.context;

        return (
            <div key={`intermediate-subtrip-${index}`} onClick={() => this.handleSubtripClick(subtrip)}>

                {
                    subtrip.isWalking ?
                        <div className="d-flex align-items-center padding-top-10 padding-bottom-10 cursor-pointer">
                            <div className="width-50px text-align-center">
                                <img src="icons/walking.png" alt="" height="20px" />
                            </div>
                            <div className="vertical-dotted-line" />
                            <span className="padding-left-10">{strings.walkAround} {subtrip.walkingTime}, {subtrip.distance}m</span>
                        </div>
                        :
                        <div className="d-flex align-items-center padding-top-10 padding-bottom-10 cursor-pointer">
                            <div className="width-50px text-align-center">
                                <img src="icons/bus.png" alt="" height="20px" />
                            </div>
                            <div className="vertical-filled-line" />
                            &nbsp;
                            <div className="padding-left-10">
                                <img src={getLogo(subtrip.provider)} alt={subtrip.provider} height="20px" />
                                &nbsp;
                                <span>{subtrip.lineCode} {subtrip.passings[0].destination}</span>
                            </div>
                        </div>
                }

                <span className="plan-route-detailed-view-subtrip-hour-span">{subtrip.arrivalTimeToShow}</span>
                <img className="margin-left-negative3" src="icons/prDestination.png" alt="" width="25px" height="25px" />
                &nbsp;
                <span>{subtrip.passings[subtrip.passings.length - 1].name}</span>
            </div>
        );

        /*return (
            <div key={`intermediate-subtrip-${index}`}>
                {
                    subtrip.isWalking ?
                        <div className="padding-top-10 padding-bottom-10">
                            <img src="icons/walking.png" alt="" width="20px" height="20px" />
                            <span>{strings.walkAround} {subtrip.walkingTime}, {subtrip.distance}m</span>
                        </div>
                        :
                        //TO DO: Use transport types here
                        <div className="padding-top-10 padding-bottom-10" >
                            <img src="icons/bus.png" alt="" width="20px" height="20px" />
                            &nbsp;
                            <img src={getLogo(subtrip.provider)} alt={subtrip.provider} width="20px" height="20px" />
                            &nbsp;
                            <span>{subtrip.lineCode} {subtrip.passings[0].destination}</span>
                        </div>
                }

                <img className="margin-left-5" src="icons/prIntermediate.png" alt="" width="10px" height="10px" />
                &nbsp;
                <span>{subtrip.passings[subtrip.passings.length - 1].name}</span>
                <br />
                {
                    trip.subTrips[index + 1].isWalking ?
                        <span className="padding-left-3em">{strings.arrivalEstimatedAt} {subtrip.arrivalTimeToShow}</span>
                        :
                        <span className="padding-left-3em">{strings.estimatedWaitingTime} {subtrip.arrivalTimeToShow} {strings.and} {trip.subTrips[index + 1].departureTimeToShow}</span>
                }
            </div>
        );*/
    }

    renderLastSubtrip(trip, subtrip) {
        const { getLogo } = this.context;

        return (
            <div key={`last-subtrip-${trip.startPlace.name}-${trip.endPlace.name}-${subtrip.order}`} onClick={() => this.handleSubtripClick(subtrip)}>
                {
                    subtrip.isWalking ?
                        <div className="d-flex align-items-center padding-top-10 padding-bottom-10 cursor-pointer">
                            <div className="width-50px text-align-center">
                                <img src="icons/walking.png" alt="" height="20px" />
                            </div>
                            <div className="vertical-dotted-line" />
                            <span className="padding-left-10">{strings.walkAround} {subtrip.walkingTime}, {subtrip.distance}m</span>
                        </div>
                        :
                        <div className="d-flex align-items-center padding-top-10 padding-bottom-10 cursor-pointer">
                            <div className="width-50px text-align-center">
                                <img src="icons/bus.png" alt="" height="20px" />
                            </div>
                            <div className="vertical-filled-line" />
                            &nbsp;
                            <div className="padding-left-10">
                                <img src={getLogo(subtrip.provider)} alt={subtrip.provider} height="20px" />
                                &nbsp;
                                <span>{subtrip.lineCode} {subtrip.passings[0].destination}</span>
                            </div>
                        </div>
                }

                <span className="plan-route-detailed-view-subtrip-hour-span">{subtrip.arrivalTimeToShow}</span>
                <img className="margin-left-negative3" src="icons/prDestination.png" alt="" width="25px" height="25px" />
                &nbsp;
                <b>{trip.endPlace.name}</b>

            </div>
        );


        /*return (
            <div key={`last-subtrip-${trip.startPlace.name}-${trip.endPlace.name}-${subtrip.order}`}>
                {
                    subtrip.isWalking ?
                        <div className="padding-top-10 padding-bottom-10">
                            <img src="icons/walking.png" alt="" width="20px" height="20px" />
                            <span>{strings.walkAround} {subtrip.walkingTime}, {subtrip.distance}m</span>
                        </div>
                        :
                        //TO DO: Use transport types here
                        <div className="padding-top-10 padding-bottom-10" >
                            <img src="icons/bus.png" alt="" width="20px" height="20px" />
                            &nbsp;
                            <img src={getLogo(subtrip.provider)} alt={subtrip.provider} width="20px" height="20px" />
                            &nbsp;
                            <span>{subtrip.lineCode} {subtrip.passings[0].destination}</span>
                        </div>
                }

                <img src="icons/prDestination.png" alt="" width="20px" height="20px" />
                &nbsp;
                <b>{subtrip.arrivalTimeToShow} - {trip.endPlace.name}</b>

            </div>
        );*/
    }

    render() {
        return (
            <Container fluid className="route-detailed-view-container">
                {this.renderDisplay()}
            </Container>
        );
    }
}