import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
import { strings } from '../../resources/strings';
import { ticketOfficePageIcon } from '../leaflet/StopIcons';

export class TicketOffice extends Component {
    render() {
        return (
            <Container fluid className="mb-5" >
                <Row>
                    <Col lg={4}>
                        <div class="mt-5">
                            <b>{strings.contactHeader}</b><br />
                            <b>Terminal Rodoviário de Évora<br />
                                Av.Tulio Espanca - Terminal Rodoviário<br/>
                                7005 - 840 Évora</b><br />
                            <b>{strings.email}:</b> geral@transportesalentejolitoral.pt<br />
                            <b>Fax: 266 738 130</b>
                            <b>{strings.phone}:</b> 269 034 827<br /><br />
                            <p>{strings.contactWorkingDays}<br />
                            {strings.contactSaturdays}<br />
                            {strings.contactSundays}</p>
                        </div>

                        <MapContainer className="map-container-ticket-office" center={[38.009452664380355, -7.859044138256686]} zoom={16} scrollWheelZoom={true}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                            />
                            <Marker
                                key={`to-terminal-de-evora`}
                                position={[38.568026, -7.9206027]}
                                icon={ticketOfficePageIcon}>

                                <Tooltip>
                                    <strong>Terminal Rodoviário de Évora</strong>
                                </Tooltip>
                            </Marker>
                        </MapContainer>
                    </Col>
                    <Col lg={4}>
                        <div class="mt-5">
                            <b>{strings.contactHeader}</b><br />
                            <b>Santiago do Cacém<br />
                                Rua Cidade de Setúbal nº14<br/>
                                7540 - 150 Santiago do Cacém</b><br />
                            <b>{strings.email}:</b> geral@transportesalentejolitoral.pt<br />
                            <b>Fax: 269 818 750</b>
                            <b>{strings.phone}:</b> 269 827 148<br /><br />
                            <p>{strings.contactWorkingDays}<br />
                            {strings.contactSaturdays}<br />
                            {strings.contactSundays}</p>
                        </div>

                        <MapContainer className="map-container-ticket-office" center={[38.009452664380355, -7.859044138256686]} zoom={16} scrollWheelZoom={true}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                            />
                            <Marker
                                key={`to-santiago-do-cacem`}
                                position={[38.0185545, -8.6958755]}
                                icon={ticketOfficePageIcon}>

                                <Tooltip>
                                    <strong>Santiago do Cacém</strong>
                                </Tooltip>
                            </Marker>
                        </MapContainer>
                    </Col>
                </Row>
            </Container>
        );
    }
}
