import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { strings } from '../resources/strings.js';

export class Disclaimer extends Component {

    render() {
        const { marginClass } = this.props;

        return (
            <div>
                <Container fluid>
                    <div className={`disclaimer ${marginClass}`}>
                        <p>
                            {strings.disclaimer}
                        </p>
                    </div>
                </Container>
            </div>
        )
    }
}