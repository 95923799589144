import { API, HTTPMethod, ContentType } from './APICentral';
import { APIEncoder } from './APIEncoder';

const poiApiRoutes = {
    defaultRoute: "poi/"
};

export class POIAPI {
    static getNearPOI(latitude, longitude, radius) {
        const url = `${poiApiRoutes.defaultRoute}${latitude}/${longitude}/${radius}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }
}