import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { strings } from '../../resources/strings';

export class ServiceOnDemand extends Component {
    render() {
        return (
            <Container fluid>
                <Row>
                    <div className="mt-5">{strings.moreInfoSoon}</div>
                </Row>
            </Container>
        );
    }
}