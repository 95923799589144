import { LogoAPI } from '../../api/LogoAPI';

export class LogoCommand {
    getLogos(successCallback, errorCallback) {
        const apiResult = LogoAPI.getLogos();
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }
}