export class APIEncoder {

    static getApiKey() {
        const timestampEncoder = {
            "0": "F",
            "1": "C",
            "2": "5",
            "3": "Z",
            "4": "H",
            "5": "S",
            "6": "W",
            "7": "0",
            "8": "8",
            "9": "K",
        };
        const monthEncoder = {
            "0": "I",
            "1": "2",
            "2": "M",
            "3": "O",
            "4": "A",
            "5": "C",
            "6": "B",
            "7": "F",
            "8": "9",
            "9": "K",
            "10": "V",
            "11": "Y",
        };
        const dayOfWeekEncoder = {
            "0": "T",
            "1": "B",
            "2": "D",
            "3": "N",
            "4": "0",
            "5": "W",
            "6": "R",
        };
        const currentDate = new Date();
        const timestamp = `${currentDate.getTime()}`;

        let key = "";
        for (let i = 0; i < timestamp.length; i++) {
            key += timestampEncoder[timestamp.charAt(i)];
        }
        key = `${key}-${monthEncoder[currentDate.getUTCMonth()]}${dayOfWeekEncoder[currentDate.getUTCDay()]}`;
        return key;
    }
}