import { ScheduleAPI } from '../../api/ScheduleAPI';

export class ScheduleCommand {
    getScheduleByLineAndDate(lineId, stopId, direction, date, successCallback, errorCallback) {
        const apiResult = ScheduleAPI.getScheduleByLineAndDate(lineId, stopId, direction, date);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }

    getScheduleByLineGroupByDayType(lineId, successCallback, errorCallback) {
        const apiResult = ScheduleAPI.getScheduleByLineGroupByDayType(lineId);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }
}
