import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import 'leaflet/dist/leaflet.css';
import { Container, Col, Row, Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import { strings } from '../../resources/strings';
import { LinePathInfoMap } from './LinePathInfoMap';


export class LinePathInfo extends Component {
    static contextTypes = {
        getLogo: PropTypes.func,
        getMenuState: PropTypes.func,
        setMenuState: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedPath: undefined,
            selectedPathStop: undefined,
            displayPathInfo: true,
            displayMap: true,
            displayPathsDropdown: false
        }
    }

    componentDidMount() {
        const { paths } = this.props;
        if (paths.length > 0) {
            this.setState({
                selectedPath: paths[0]
            });
        }

        //Set function to handle when window is resized
        window.onresize = () => this.resize();

        //Call resize function to check the current width, otherwise resizing the window is the only trigger;  
        this.resize();
    }

    componentWillUnmount() {
        window.onresize = () => { };
    }

    async resize() {
        if (window.innerWidth > 767) {
            this.setState({
                displayPathInfo: true,
                displayMap: true
            });
        } else {
            let button = document.getElementById("line-path-info-show-stops-button");

            /*
             * If the button is null, it's because this is the initial check and the button hasn't been rendered yet. 
             * Since we are not retrieving async info from anywhere, a possible workaround is to wait a few seconds (0.5s as of now) and try again.
             */
            if (null === button) {
                await new Promise(r => setTimeout(r, 500));
                this.resize();
            } else {
                button.click();
            }

            if (this.context.getMenuState()) {
                this.context.setMenuState();
            }
        }
    }

    //HELPER FUNCTIONS
    togglePathsDropdown() {
        const { displayPathsDropdown } = this.state;
        this.setState({
            displayPathsDropdown: !displayPathsDropdown
        })
    }

    handlePathSelection(path) {
        this.setState({
            selectedPath: path
        });
    }

    handleSelectedPathStop(place) {
        this.setState({
            selectedPathStop: place
        });
    }

    displayPathInfo() {
        this.setState({
            displayPathInfo: true,
            displayMap: false
        });
    }

    displayMap() {
        this.setState({
            displayPathInfo: false,
            displayMap: true
        });
    }

    //--------------
    renderProviderLogo(provider) {
        const { getLogo } = this.context;
        const imgUrl = getLogo(provider);

        if (null === imgUrl) {
            return null;
        }

        return (
            <img height="40px" src={imgUrl} alt={`${provider}`} />
        );
    }

    renderInfo() {
        const { selectedPath, selectedPathStop, displayPathInfo, displayMap } = this.state;

        if (displayPathInfo && displayMap) {
            return (
                <Row>
                    <Col sm={5} className="line-path-info-container-trip-col">
                        {this.renderPathHeader(selectedPath)}
                        {this.renderPath(selectedPath)}
                    </Col>
                    {<Col sm={7} className="line-path-info-container-map-col">
                        <LinePathInfoMap path={selectedPath} selectedPathStop={selectedPathStop} makeTooltipsPermanent={true} />
                    </Col>}
                </Row>
            );
        } else {
            if (displayPathInfo) {
                return (
                    <Row>
                        <Col sm={12} className="line-path-info-container-trip-col">
                            {this.renderPathHeader(selectedPath)}
                            {this.renderPath(selectedPath)}
                        </Col>
                    </Row>
                );
            } else {
                return (
                    <Row>
                        <Col sm={12} className="line-path-info-container-map-col">
                            {this.renderPathHeader(selectedPath)}
                            <LinePathInfoMap path={selectedPath} selectedPathStop={selectedPathStop} makeTooltipsPermanent={true} />
                        </Col>
                    </Row>
                );
            }
        }
    }

    renderPathHeader(path) {
        const { displayMap, displayPathInfo, selectedPath } = this.state;
        const { onReturn } = this.props;

        if (undefined === path || 0 === path.places.length) {
            return (
                <div className="line-path-info-header">
                    <div>
                        <Button className="line-path-info-back-button" variant="light" onClick={() => onReturn()}>
                            <div className={"icon-redo"} />
                        </Button>
                    </div>
                    <div className="line-path-info-panel-header text-align-center">
                        <span>{strings.noInfoToShow}</span>
                    </div>
                </div>
            );
        }

        const { line, provider } = this.props;

        return (
            <div className="line-path-info-header">

                <Button className="line-path-info-back-button" variant="light" onClick={() => onReturn()}>
                    <div className={"icon-redo"} />
                </Button>
                <div className="line-path-info-small-screen-tabs">
                    <Button id="line-path-info-show-stops-button" className={`line-path-info-small-screen-tabs-link-button ${displayPathInfo ? "orange-text" : ""}`} variant="link" onClick={() => this.displayPathInfo()}>
                        {strings.showStops}
                    </Button>
                    <span className="orange-text">|</span>
                    <Button id="line-info-show-map-button" className={`line-path-info-small-screen-tabs-link-button ${displayMap ? "orange-text" : ""}`} variant="link" onClick={() => this.displayMap()}>
                        {strings.showMap}
                    </Button>
                </div>

                <div className="line-path-info-panel-header">
                    <Row>
                        <Col sm={6}>
                            <div className="line-path-info-panel-header-provider-info">
                                {this.renderProviderLogo(provider)}
                                <div className="line-path-info-panel-header-trip-info">
                                    <div><b>{line.code}</b> {line.name}</div>
                                    <div>{`${path.places[0].name} > ${path.places[path.places.length - 1].name}`}</div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="line-path-info-path-dropdown" onClick={() => this.togglePathsDropdown()}>
                                <Button variant="secondary" className="dropdown-toggle schedules-line-button" id="pathDropdown">
                                    {
                                        selectedPath !== undefined && selectedPath.places.length > 0 ?
                                            <span className="overflow-x-clip">
                                                {selectedPath.places[0].name} > {selectedPath.places[selectedPath.places.length - 1].name}
                                            </span>
                                            :
                                            <span className="line-path-info-path-dropdown-placeholder overflow-x-clip">{strings.selectPath}</span>
                                    }
                                </Button>
                                {this.renderPathsDropdown()}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    renderPathsDropdown() {
        const { displayPathsDropdown } = this.state;
        const { paths } = this.props;

        if (!displayPathsDropdown) {
            return null;
        }

        return (
            <div className="line-path-info-paths-dropdown-panel">
                <ListGroup className="line-path-info-paths-dropdown-list">
                    {
                        paths.map((path, index) =>
                            <ListGroupItem key={`path-${index}`} onClick={() => this.handlePathSelection(path)}>
                                <div className="line-info-paths-dropdown-path-info">
                                    {
                                        path.places.length > 0 ?
                                            <span className="overflow-x-clip">{path.places[0].name} > {path.places[path.places.length - 1].name}</span>
                                            :
                                            null
                                    }
                                </div>
                            </ListGroupItem>
                        )
                    }
                </ListGroup>
            </div>
        );
    }

    renderPath(selectedPath) {
        if (undefined === selectedPath || 0 === selectedPath.places.length) {
            return null;
        }

        return (
            <div className="line-path-info-panel">
                <span className="line-path-info-stops-title">{strings.lineInfoStopsTitle}</span>

                <Container fluid className="line-path-info-passings-list">
                    {
                        selectedPath.places.map((place, index) =>

                            <Row key={`line-info-passing-${index}`} onClick={() => this.handleSelectedPathStop(place)}>
                                <Col xs={1}>
                                    <img src="icons/lineInfoMarker.png" alt="" />
                                </Col>
                                <Col xs={11} className="d-flex justify-content-between">
                                    <div className="d-inline-block text-truncate">{place.name} ({place.code})</div>
                                </Col>
                            </Row>
                        )
                    }
                </Container>
            </div>
        );
    }

    render() {
        return (
            <Container fluid className="line-path-info-container">
                {this.renderInfo()}
            </Container>
        );
    }
}
