import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { strings } from '../resources/strings.js';

export class Footer extends Component {

    render() {
        const { marginClass } = this.props;

        return (
            <div>
                <Container fluid>
                    <div className={`footer ${marginClass}`}>
                        <p>
                            <a href="https://www.opt.pt" target="_blank" rel="noopener noreferrer">{strings.copyright}</a>. {strings.developedFor}. {strings.allRightsReserved}. <LinkContainer to="/privacypolicy"><a>{strings.privacyPolicy}</a></LinkContainer> | <a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank" rel="noopener noreferrer">{strings.complaintsBook}</a> | <a href="docs/direitos_autocarros.pdf" target="_blank" rel="noopener noreferrer">{strings.passengerRights}</a>
                        </p>
                    </div>
                </Container>
            </div>
        )
    }
}