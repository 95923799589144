import { API, HTTPMethod, ContentType } from './APICentral';
import { APIEncoder } from './APIEncoder';

const providerApiRoutes = {
    defaultRoute: "provider/",
    providerLines: "provider/lines/",
    linePaths: "provider/paths/"
};

export class ProviderAPI {
    static getProviders() {
        const url = `${providerApiRoutes.defaultRoute}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }

    static getProviderLines(providerName) {
        const url = `${providerApiRoutes.providerLines}${providerName}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }

    static getProviderLinePaths(lineIds) {
        const url = `${providerApiRoutes.linePaths}${lineIds}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }
}