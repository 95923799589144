import { API, HTTPMethod, ContentType } from './APICentral';
import { APIEncoder } from './APIEncoder';

const searchApiRoutes = {
    performSearch: "search/"
};

export class SearchAPI {
    static performSearch(query) {
        const url = `${searchApiRoutes.performSearch}${query}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }
}
