import { MessageAPI } from '../../api/MessageAPI';

export class MessageCommand {
    getMessages(successCallback, errorCallback) {
        const apiResult = MessageAPI.getMessages();
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }
}