import { API, HTTPMethod, ContentType } from './APICentral';
import { APIEncoder } from './APIEncoder';

const stopApiRoutes = {
    defaultRoute: "message/"
};

export class MessageAPI {
    static getMessages() {
        const url = `${stopApiRoutes.defaultRoute}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }
}