import { API, HTTPMethod, ContentType } from './APICentral';
import { APIEncoder } from './APIEncoder';

const logoApiRoutes = {
    defaultRoute: "logo/"
};

export class LogoAPI {
    static getLogos() {
        const url = `${logoApiRoutes.defaultRoute}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }
}