import { TripAPI } from '../../api/TripAPI';

export class TripCommand {
    getTrip(tripId, successCallback, errorCallback) {
        const apiResult = TripAPI.getTrip(tripId);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }

    getEstimatedTrip(provider, lineId, direction, stopId, successCallback, errorCallback) {
        const apiResult = TripAPI.getEstimatedTrip(provider, lineId, direction, stopId);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }

    getTripsByLineAndDayType(lineId, direction, schedules, successCallback, errorCallback) {
        const apiResult = TripAPI.getTripsByLineAndDayType(lineId, direction, schedules);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }

    getVehiclePosition(provider, vehicleNr, vehicleTrip, successCallback, errorCallback) {
        const apiResult = TripAPI.getVehiclePosition(provider, vehicleNr, vehicleTrip);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }
}