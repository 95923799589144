import { ContactAPI } from '../../api/ContactAPI';

export class ContactCommand {
    sendEmail(message, successCallback, errorCallback) {
        const apiResult = ContactAPI.sendEmail(message);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback();
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback();
            }
        });
    }
}
