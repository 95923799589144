import { SearchAPI } from '../../api/SearchAPI';

export class SearchCommand {
    performSearch(query, successCallback, errorCallback) {
        const apiResult = SearchAPI.performSearch(query);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }
}
