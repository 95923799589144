import React, { Component } from 'react';

export class FilterLinesButton extends Component {
    render() {
        const { className, filter } = this.props;

        return (
            <div className={className}>
                <button className="filter-lines-button" onClick={() => filter()}>
                    <div className="icon-filter" />
                </button>
            </div>
        );
    }
}