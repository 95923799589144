import React, { Component } from 'react';
import { Row, Col, ListGroup, ListGroupItem, Spinner } from 'react-bootstrap';
import { strings } from '../../resources/strings';
import { MessageCommand } from '../commands/MessageCommand';

export class Messages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            messages: [],
            isLoadingMessages: true,
            commands: {
                messages: new MessageCommand()
            },
        }
    }

    componentDidMount() {
        this.getMessages();
    }

    //MESSAGES
    getMessages() {
        this.setState({
            isLoadingMessages: true
        });

        const { commands } = this.state;
        commands.messages.getMessages((r) => this.getMessagesSuccessCallback(r));
    }

    getMessagesSuccessCallback(result) {
        this.setState({
            messages: result,
            isLoadingMessages: false
        });
    }
    //----------------

    renderMessage(message, index) {
        if (message.type.toLowerCase() === 'aviso' || message.type.toLowerCase() === 'warning') {
            return (
                <ListGroupItem key={`message-creation-date-${index}`}>
                    <Row className="messages-list-message-row">
                        <Col sm={12} className="d-flex align-items-center">
                            <b>{message.creationDateToShow}</b>
                        </Col>
                    </Row>
                    <Row className="messages-list-message-row">
                        <Col sm={12} className="d-flex align-items-center">
                            <div dangerouslySetInnerHTML={{ __html: message.text }}></div>
                        </Col>
                    </Row>
                </ListGroupItem>
            );
        }

        if (message.type.toLowerCase() === 'imagem' || message.type.toLowerCase() === 'image') {
            return (
                <ListGroupItem key={`message-creation-date-${index}`}>
                    <Row className="messages-list-message-row">
                        <Col sm={12} className="d-flex align-items-center">
                            <b>{message.creationDateToShow}</b>
                        </Col>
                    </Row>
                    <Row className="messages-list-message-row">
                        <Col sm={12} className="d-flex align-items-center">
                            <img alt={message.httpLink} src={message.httpLink} className="warning-image" />
                        </Col>
                    </Row>
                </ListGroupItem>
            );
        }

        return null;
    }

    render() {
        const { isLoadingMessages, messages } = this.state;

        if (isLoadingMessages) {
            return (
                <div className="messages-panel">
                    <ListGroup className="messages-list">
                        <ListGroupItem key="messages-spinner" className="text-align-center">
                            <Spinner animation="border" role="status" />
                        </ListGroupItem>
                    </ListGroup>
                </div>
            );
        }

        if (null === messages) {
            return (
                <div className="messages-panel">
                    <ListGroup className="messages-list">
                        <ListGroupItem key={`messages-no-info}`}>
                            <Row>
                                <Col sm={12}>
                                    {strings.noMessagesToShow}
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </div>
            )
        }

        return (
            <div className="next-departures-view mb-5">
                <div className="messages-panel">
                    <ListGroup className="messages-list">
                        {
                            0 !== messages.length ?
                                messages.map((message, index) => this.renderMessage(message, index))
                                :
                                <ListGroupItem key={`message-no-info}`}>
                                    <Row>
                                        <Col sm={12}>
                                            {strings.noMessagesToShow}
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                        }
                    </ListGroup>
                </div>
            </div>
        );
    }
}
