export const HTTPMethod = {
    GET: "GET",
    POST: "POST",
    DELETE: "DELETE",
    PUT: "PUT",
    PATCH: "PATCH"
};

export const ContentType = {
    JSON: "application/json; charset=utf-8",
    FORM: "application/x-www-form-urlencoded",
    MULTIPART: `multipart/form-data`
};

export class API {
    static concatResponse(modelState, response) {
        modelState.forEach(function (value) {
            response = response.concat('\n' + value)
        });
        return response;
    }

    static authenticatedRequest(parameters, justContent = true) {
        var headers = new Headers();

        if (ContentType.MULTIPART !== parameters.contentType) {
            headers.set("Content-Type", parameters.contentType || ContentType.FORM);
        }

        if (parameters.token !== undefined) {
            headers.set("Authorization", "Bearer " + parameters.token);
        }

        if (parameters.key !== undefined) {
            headers.set("ApiKey", parameters.key);
        }

        return fetch(parameters.url, {
            method: parameters.method,
            headers: headers,
            body: parameters.body || undefined
        }).then(async response => {
            if (!response.ok) {
                const responseText = await response.text();

                if (responseText !== "") {
                    let responseJSON = JSON.parse(responseText);

                    if (responseJSON.message) {
                        var responsePrint = responseJSON.message;

                        if (responseJSON.modelState) {
                            for (let state in responseJSON.modelState) {
                                responsePrint = this.concatResponse(responseJSON.modelState[state], responsePrint);
                            }
                        }
                    }
                }

                const error = {
                    status: response.status,
                    statusText: response.statusText,
                    message: "Ocorreu um erro."
                };

                if (400 === response.status) {
                    error.message = `${responsePrint !== undefined ? responsePrint : ""}`.trim();
                } else if (401 === response.status) {
                    error.message = "Acesso negado.";
                }

                throw error;
            }
            if (justContent) {
                return response.text();
            }
            return response;
        }).catch((error) => {
            throw error;
        });
    }
}
